<template>
  <div class="booking">
    <h1>Booking Page.. lets see if we can add the calendly api here..</h1>      
  </div>
</template>

<script>


export default {
  name: 'Booking',
  components: {
    
  }
}
</script>
